import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { FormGrid, TextField, Button, Space } from 'components';
import { useForm, useService } from 'hooks';
import { AuthContext } from 'contexts';
import validate from 'helpers/validate';

import * as S from './styled';

interface LoginProps {
  type: 'ADMIN' | 'BIRDIE';
}

const validations = {
  email: [validate.isEmpty(), validate.isEmail()],
  password: [validate.isEmpty()],
};

const LOGIN_URL = {
  ADMIN: '/admin/login',
  BIRDIE: '/birdie/login',
};

const HOME = {
  ADMIN: '/dashboard',
  BIRDIE: '/dashboard',
}

const Login: React.FC<LoginProps> = ({ type }) => {
  const navigate = useNavigate();
  const { setAuth, state } = AuthContext.useAuth();
  const loginService = useService('post', LOGIN_URL[type], {}, false);

  const [form, onChange] = useForm({ validations });

  const handleOnSubmit = async () => {
    const data = {
      mail: form.values.email,
      password: form.values.password,
      type: 'WEB',
    };

    const response = await loginService.fetch(data);

    if (response?.data?.status === 'OK') {
      setAuth({ token: response.data.token, isLogged: true });

      return navigate(HOME[type]);
    }

    if (response.data.status === 'INVALID') {
      return toast('Dados de entrada inválidos', { type: 'error' });
    }

    return toast('Houve um erro ao fazer login', { type: 'error' });
  };

  const formConfig = [
    [
      {
        schema: 'email',
        size: { md: 12 },

        type: TextField,
        props: (schema) => ({
          label: 'E-mail',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
    ],
    [
      {
        schema: 'password',
        size: { md: 12 },

        type: TextField,
        props: (schema) => ({
          label: 'Senha',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          type: 'password',
        }),
      },
    ],
  ];

  React.useEffect(() => {
    if (state.isLogged) {
      navigate(HOME[state.user?.type!]);
    }
  }, [state.isLogged]);

  return (
    <S.Container>
      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Space top={20} />

        <Button width="100%" type="submit" loading={loginService.loading}>
          Entrar
        </Button>
      </FormGrid>

      <Space top={20} />

      <Button variant="transparent" to="/register" width="100%">
        Não possuo conta
      </Button>
    </S.Container>
  );
};

export default Login;
