import React from 'react';
import { Outlet } from 'react-router-dom';

import Menu from './components/Menu';
import * as S from './styled';

const UserLayout: React.FC = () => {
  return (
    <S.Container>
      <Menu />

      <S.Body>
        <Outlet />
      </S.Body>
    </S.Container>
  );
};

export default UserLayout;
