import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'contexts';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { setLogout, state } = AuthContext.useAuth();

  React.useEffect(() => {
    setLogout();
  }, []);

  React.useEffect(() => {
    navigate('/');
  }, [state.isLogged]);

  return null;
};

export default Login;
