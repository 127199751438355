import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { FormGrid, TextField, Button, Space } from 'components';
import { useForm, useService } from 'hooks';
import { AuthContext } from 'contexts';
import validate from 'helpers/validate';

import * as S from './styled';

const validations = {
  birdie: [validate.isEmpty()],
  name: [validate.isEmpty()],
  mail: [validate.isEmpty(), validate.isEmail()],
  password: [validate.isEmpty()],
  repassword: [validate.isEmpty(), validate.isEqual('password', 'Senhas não coincidem')],
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { setAuth } = AuthContext.useAuth();
  const registerService = useService('post', '/birdie/register', {}, false);

  const [form, onChange] = useForm({ validations });

  const handleOnSubmit = async () => {
    const data = {
      birdie: form.values.birdie,
      name: form.values.name,
      mail: form.values.mail,
      password: form.values.password,
    };

    const response = await registerService.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Usuário criado com sucesso');
      setAuth({ token: response.data.token, isLogged: true });

      return navigate('/dashboard');
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao criar usuário', { type: 'error' });
  };

  const formConfig = [
    [
      {
        schema: 'birdie',
        size: { md: 12 },

        type: TextField,
        props: (schema) => ({
          label: 'Nome do criadouro',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
    ],
    [
      {
        schema: 'name',
        size: { md: 12 },

        type: TextField,
        props: (schema) => ({
          label: 'Seu nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
    ],
    [
      {
        schema: 'mail',
        size: { md: 12 },

        type: TextField,
        props: (schema) => ({
          label: 'Seu e-mail',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
    ],
    [
      {
        schema: 'password',
        size: { md: 12 },

        type: TextField,
        props: (schema) => ({
          label: 'Informe uma senha',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          type: 'password',
        }),
      },
    ],
    [
      {
        schema: 'repassword',
        size: { md: 12 },

        type: TextField,
        props: (schema) => ({
          label: 'Confirme a senha',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          type: 'password',
        }),
      },
    ],
  ];

  return (
    <S.Container>
      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Space top={20} />

        <Button width="100%" type="submit" loading={registerService.loading}>
          Criar conta
        </Button>
      </FormGrid>

      <Space top={20} />

      <Button variant="transparent" to="/" width="100%">
        Voltar
      </Button>
    </S.Container>
  );
};

export default Login;
