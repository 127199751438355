import React from 'react';
import { Route, Routes } from 'react-router-dom';
import List from './list';
import Form from './form';

const DietComponentPrepare = (): JSX.Element => {
  return (
    <Routes>
      <Route index element={<List />} />
      <Route path="create" element={<Form />} />
      <Route path="edit/:diet" element={<Form />} />
    </Routes>
  );
};
export default DietComponentPrepare;
