import React from 'react';
import * as R from 'ramda';

import { useState, useService } from 'hooks';
import * as Types from 'types/contexts/auth';

interface AuthContextProps {
  children: any;
}

const AuthContext = React.createContext<Types.IAuthValue>({ state: {}, setAuth: () => null, setLogout: () => null });

const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
  const [state, setState] = useState<Types.IAuthState>({});
  const [loaded, setLoaded] = useState<boolean>(false);

  const getAuthService = useService('get', 'auth/get-data', {}, false);

  const getUserData = async (token: string) => {
    const result = await getAuthService.fetch({}, {}, { Authorization: token });

    if (result?.data?.status === 'OK') {
      return R.omit(['status'], result.data);
    }

    if (result?.data?.status === 'NOT_AUTHORIZED') {
      setLogout();
    }

    throw new Error('Erro');
  };

  const setLogout = () => {
    localStorage.removeItem('auth');
    setState(() => ({}));
  };

  const setAuth = async (data: Types.IAuthState) => {
    getUserData(data.token!).then((sessionData) => {
      setState({
        ...data,
        ...sessionData,
      });
    });
  };

  React.useEffect(() => {
    if (state.isLogged) {
      localStorage.setItem('auth', JSON.stringify(state));
    }
  }, [state]);

  React.useEffect(() => {
    const json = localStorage.getItem('auth');

    if (!json) {
      setLoaded(true);
      return;
    }

    const data = JSON.parse(json) as Types.IAuthState;

    if (!data?.token) {
      setLoaded(true);
      return;
    }

    getUserData(data.token!)
      .then((sessionData) => {
        setState({
          ...data,
          ...sessionData,
        });
        setLoaded(true);
      })
      .catch((err) => {
        setLoaded(true);
      });
  }, []);

  const value = {
    state,
    setAuth: (data: Partial<Types.IAuthState>) => setAuth(data),
    setLogout,
  };

  if (!loaded) return null;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default {
  Provider: AuthProvider,
  Consumer: AuthContext.Consumer,
  useAuth: () => React.useContext(AuthContext),
};
