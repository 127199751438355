import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Body } from 'components';

import Form from './form';
import List from './list';

const Specie: React.FC = () => {
  return (
    <Body>
      <Routes>
        <Route index element={<List />} />
        <Route path="create" element={<Form />} />
        <Route path="edit/:user" element={<Form />} />
      </Routes>
    </Body>
  );
};

export default Specie;
