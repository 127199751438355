import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useService } from 'hooks';
import { TableList, Header, Button } from 'components';

const PAGE_KEY = 'GENERAL_PAGE';

const List: React.FC = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get(PAGE_KEY) || 1;

  const listService = useService('get', `/weight-unit`, { page: currentPage }, true, [currentPage]);

  const handleOnChangePage = (page) => {
    setSearchParams({ [PAGE_KEY]: page });
  };

  const tableListConfig = [
    {
      path: 'name',
      title: 'Nome',
      size: {
        md: 12,
      },
    },
  ];

  return (
    <>
      <Header title="Unidades de peso" right={<Button to={`${location.pathname}/create`}>Novo</Button>} />

      <TableList
        config={tableListConfig}
        data={listService?.data?.data}
        dataPath="weightUnit.items"
        paginate
        paginatePath="weightUnit"
        onChangePage={handleOnChangePage}
      />
    </>
  );
};

export default List;
