import React from 'react';
import { useLocation, useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useService } from 'hooks';
import { TableList, TableListButtons, TableListButtonsProps, Header, Button } from 'components';
import { BIRD_HEALTH_TYPES, getLabel } from 'helpers/consts';

const PAGE_KEY = 'BIRD_QUESTION_PAGE';
const BACK_ROUTE = `/params/bird/health`;

const List: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ health?: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get(PAGE_KEY) || 1;

  const healthService = useService('get', `/bird-health/${params.health}`);
  const listService = useService('get', `/bird-health/${params.health}/question`, { page: currentPage }, true, [
    currentPage,
  ]);
  const removeService = useService('patch', (data) => `/bird-health/${params.health}/question/${data?.id}`, {}, false);

  const handleOnActive = async (row: any) => {
    const response = await removeService.fetch({ isActive: !row.isActive }, { id: row._id });

    if (response?.data?.status === 'OK') {
      toast('Saúde inativada');
      return listService.fetch();
    }

    return toast('Houve um erro ao inativar', { type: 'error' });
  };

  const handleOnChangePage = (page) => {
    setSearchParams({ [PAGE_KEY]: page });
  };

  const handleRowOnEdit = (row: DBird.IBirdColor) => {
    navigate(`${location.pathname}/edit/${row._id}`);
  };

  const tableListButtonsConfig: TableListButtonsProps['config'] = [
    {
      icon: (row) => (row.isActive ? 'eye-slash' : 'eye'),
      label: 'Mostrar no app',
      onClick: handleOnActive,
    },
    {
      icon: 'pencil',
      label: 'Editar',
      onClick: handleRowOnEdit,
    },
  ];

  const tableListConfig = [
    {
      path: 'name',
      title: 'Nome',
      size: {
        md: 10,
      },
    },
    {
      path: (row) => <TableListButtons data={row} config={tableListButtonsConfig} />,
      title: '',
      size: {
        md: 2,
      },
    },
  ];

  const title = healthService.loading ? 'Carregando...' : `${healthService?.data?.data?.birdHealth.name} - Opções`;

  return (
    <>
      <Header
        title={title}
        left={
          <Button variant="outlined" to={BACK_ROUTE}>
            Voltar
          </Button>
        }
        right={<Button to={`${location.pathname}/create`}>Novo</Button>}
      />

      <TableList
        config={tableListConfig}
        data={listService?.data?.data}
        dataPath="birdHealth.items"
        paginate
        paginatePath="birdHealth"
        onChangePage={handleOnChangePage}
      />
    </>
  );
};

export default List;
