import React from 'react';

import Grid from '../../../Grid';
import * as Types from '../../types';

interface ColumnProps extends Types.FormGridColumnProps {
  last: boolean;
}

const Column: React.FC<ColumnProps> = ({ type, schema, props, size = { md: 12 }, offset = 0, last, hide }) => {
  if (hide?.()) return null;

  const Component = React.createElement<any>(type, {
    name: schema,
    ...props?.(schema),
  });

  return (
    <Grid size={size} spacing={12}>
      {Component}
    </Grid>
  );
};

export default Column;
