import React from 'react';
import { useTheme } from 'styled-components';
import ReactSelect, { Props, MultiValueProps, SingleValueProps } from 'react-select';

import BaseInput from '../BaseInput';
import * as S from './styled';

const defaultFormat = { value: 'value', label: 'label' };
type Option = {
  [key: string]: any;
  value?: string;
  label?: string;
};

interface SelectFieldProps extends Omit<Props, 'onChange' | 'options' | 'value'> {
  label: string;
  tooltip?: string;
  onChange: (value?: MultiValueProps | SingleValueProps) => void;
  isRequired?: boolean;
  error?: string;
  mode: 'light' | 'dark';
  format?: {
    value?: string;
    label?: string;
  };
  value?: Option;
  options: Array<Option>;
  disabled?: boolean;
  loading?: boolean;
}

const SelectField: React.FC<SelectFieldProps> = ({
  label,
  tooltip,
  value,
  onChange,
  isRequired,
  error,
  mode = 'light',
  options,
  format = defaultFormat,
  disabled,
  loading,
  ...props
}) => {
  const theme = useTheme();

  const currentFormat = {
    ...defaultFormat,
    ...format,
  };

  const handleOnChange = (values) => {
    onChange?.(values);
  };

  const customStyles = {
    container: (prev) => ({
      ...prev,
      width: '100%',
    }),
    control: (prev) => ({
      ...prev,
      height: '48px',
      color: '#666666',
      borderColor: theme.palette.primary.main,
      boxShadow: 'none',
      '&:active': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    }),
    indicatorsContainer: (prev) => ({
      ...prev,
      display: 'flex',
      flexDirection: 'row',
    }),
    indicatorSeparator: (prev) => ({
      ...prev,
      display: 'none',
    }),
    valueContainer: (prev) => ({
      ...prev,
      padding: '10px 15px',
    }),
    input: (prev) => ({
      ...prev,
      fontSize: '14px',
      color: '#666666',
      margin: '0',
    }),
    singleValue: (prev) => ({
      ...prev,
      fontSize: '14px',
      color: '#666666',
      margin: '0',
    }),
    menu: (prev) => ({
      ...prev,
      marginTop: 'none',
    }),
  };

  const formatedOptions = React.useMemo(() => {
    return options?.map((item) => ({
      ...item,
      value: item?.[currentFormat.value],
      label: item?.[currentFormat.label],
    }));
  }, [options]);

  const formatedValue = React.useMemo(() => {
    return !value
      ? value
      : {
          ...value,
          value: value?.[currentFormat.value],
          label: value?.[currentFormat.label],
        };
  }, [value]);

  return (
    <BaseInput error={error}>
      <S.Container>
        <S.Label>
          {label}
          {isRequired ? ' *' : ''}
        </S.Label>

        <S.InputContent>
          <ReactSelect
            styles={customStyles}
            placeholder=""
            noOptionsMessage={() => 'Lista vazia'}
            isClearable
            onChange={handleOnChange}
            value={formatedValue}
            options={formatedOptions}
            isDisabled={disabled}
            isLoading={loading}
            {...props}
          />
        </S.InputContent>
      </S.Container>
    </BaseInput>
  );
};

export default SelectField;
