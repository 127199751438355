import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.palette.primary.main};
  padding: 0 20px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 20px 0;
`;

export const Logo = styled.img`
  height: 32px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  height: 100%;
`;

export const UserName = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.primary.text};

  :after {
    content: ' ';
    border-left: 1px solid ${({ theme }) => theme.palette.primary.text};
    margin: 0 8px;
  }
`;

export const Logout = styled(Link)`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.primary.text};
`;

export const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.primary.text};
  padding-top: 4px;
  overflow: hidden;
`;

export const NavbarItem = styled.div<{ active: boolean }>`
  padding: 12px 16px;
  color: ${({ theme }) => theme.palette.primary.text};
  font-size: 14px;
  text-decoration: none;
  transition: 0.4s;
  border: 1px solid transparent};
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  :hover {
    border-color: ${({ theme }) => theme.palette.primary.text};
  }

  ${({ active, theme }) =>
    !active
      ? ''
      : css`
          background: ${theme.palette.primary.text};
          color: ${theme.palette.primary.main};
        `};
`;
