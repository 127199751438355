import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Footer, FormGrid, FormConfig, Header, Button, TextField, Switch } from 'components';
import { useForm, useService } from 'hooks';
import validate from 'helpers/validate';

const validations = {
  name: [validate.isEmpty()],
  email: [validate.isEmpty(), validate.isEmail()],
};

const initialValues = {
  isActive: true,
};

const Form: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ user?: string }>();
  const [form, onChange] = useForm({ initialValues, validations });

  const LIST_CLASSES = `/users`;

  const createService = useService('post', `/user`, {}, false);
  const updateService = useService('patch', `/user/${params.user}`, {}, false);
  const userService = useService('get', `/user/${params.user}`, {}, !!params.user);

  const handleOnSave = async () => {
    const data = {
      status: form.getValue('isActive') ? 'ACTIVE' : 'INACTIVE',
      name: form.getValue('name'),
      email: form.getValue('email'),
    };

    const service = params.user ? updateService : createService;
    const response = await service.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Salvo com sucesso');
      return navigate(LIST_CLASSES);
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  React.useEffect(() => {
    const data = userService?.data?.data?.startupStart;

    if (!data) return;

    form.setValues({
      ...data,
      isActive: data.status === 'ACTIVE',
    });
  }, [userService.data]);

  const formConfig: FormConfig = [
    [
      {
        schema: 'isActive',
        size: { md: 6 },
        type: Switch,
        props: (schema) => ({
          label: 'Ativo',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
    ],
    [
      {
        schema: 'name',
        size: { md: 6 },
        type: TextField,
        props: (schema) => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
      {
        schema: 'email',
        size: { md: 6 },
        type: TextField,
        props: (schema) => ({
          label: 'E-mail',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
    ],
  ];

  return (
    <>
      <Header
        title={params.user ? 'Editar usuário' : 'Novo usuário'}
        left={
          <Button variant="outlined" to={LIST_CLASSES}>
            Voltar
          </Button>
        }
      />

      <FormGrid config={formConfig} />

      <Footer>
        <Button onClick={form.trySave(handleOnSave)} loading={createService.loading || updateService.loading}>
          Salvar
        </Button>
      </Footer>
    </>
  );
};

export default Form;
