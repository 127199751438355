import React from 'react';
import { toast } from 'react-toastify';

import { Footer, FormGrid, FormConfig, Header, Button, SelectField } from 'components';
import { useForm, useService } from 'hooks';
import validate from 'helpers/validate';
import { BIRDIE_PARAM_COIN, BIRDIE_PARAM_WEIGHT, BIRDIE_PARAM_TEMPERATURE, getItem } from 'helpers/consts';

const validations = {
  coinUnit: [validate.isEmptySelect()],
  weightUnit: [validate.isEmptySelect()],
  temperatureUnit: [validate.isEmptySelect()],
};

const Form: React.FC = () => {
  const [form, onChange] = useForm({ validations });

  const updateService = useService('patch', '/birdie-param', {}, false);
  const paramsService = useService('get', '/birdie-param');
  const coinUnitService = useService('get', `/coin-unit`, { limit: 1000 }, true);
  const weightUnitService = useService('get', `/weight-unit`, { limit: 1000 }, true);
  const temperatureUnitService = useService('get', `/temperature-unit`, { limit: 1000 }, true);

  const handleOnSave = async () => {
    const data = {
      coinUnit: form.getValue('coinUnit')?.value,
      weightUnit: form.getValue('weightUnit')?.value,
      temperatureUnit: form.getValue('temperatureUnit')?.value,
    };

    const service = updateService;
    const response = await service.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Parâmetros salvos com sucesso');
      return;
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  React.useEffect(() => {
    const data = paramsService?.data?.data?.birdieParam;

    if (!data) return;

    form.setValues({
      ...data,
      coinUnit: getItem(BIRDIE_PARAM_COIN, data.coinUnit),
      weightUnit: getItem(BIRDIE_PARAM_WEIGHT, data.weightUnit),
      temperatureUnit: getItem(BIRDIE_PARAM_TEMPERATURE, data.temperatureUnit),
    });
  }, [paramsService.data]);

  const formConfig: FormConfig = [
    [
      {
        schema: 'coinUnit',
        size: { md: 4 },
        type: SelectField,
        props: (schema) => ({
          label: 'Moeda',
          options: coinUnitService?.data?.data?.coinUnit?.items,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          loading: coinUnitService.loading,
          format: {
            value: '_id',
            label: 'name',
          },
        }),
      },
      {
        schema: 'weightUnit',
        size: { md: 4 },
        type: SelectField,
        props: (schema) => ({
          label: 'Unidade de peso',
          options: weightUnitService?.data?.data?.weightUnit?.items,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          loading: weightUnitService.loading,
          format: {
            value: '_id',
            label: 'name',
          },
        }),
      },

      {
        schema: 'temperatureUnit',
        size: { md: 4 },
        type: SelectField,
        props: (schema) => ({
          label: 'Unidade de temperatura',
          options: temperatureUnitService?.data?.data?.temperatureUnit?.items,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          loading: temperatureUnitService.loading,
          format: {
            value: '_id',
            label: 'name',
          },
        }),
      },
    ],
  ];

  return (
    <>
      <Header title={'Editar parâmetros'} />

      <FormGrid config={formConfig} />

      <Footer>
        <Button onClick={form.trySave(handleOnSave)} loading={paramsService.loading || updateService.loading}>
          Salvar
        </Button>
      </Footer>
    </>
  );
};

export default Form;
