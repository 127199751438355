import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Bird from './bird';
import Diet from './diet';
import Reproduction from './reproduction';
import Aviary from './aviary';
import General from './general';

const Dashboard: React.FC = () => {
  return (
    <Routes>
      <Route path="bird/*" element={<Bird />} />
      <Route path="diet/*" element={<Diet />} />
      <Route path="reproduction/*" element={<Reproduction />} />
      <Route path="aviary/*" element={<Aviary />} />
      <Route path="general/*" element={<General />} />
    </Routes>
  );
};

export default Dashboard;
