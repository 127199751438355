import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  align-items: center;
`;

export const Switch = styled.span<{
  value: boolean;
}>`
  display: flex;
  flex-direction: row;
  width: 50px;
  height: max-content;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  background: ${({ value, theme }) => (value ? theme.palette.primary.main : 'transparent')};
  border-radius: 32px;
  padding: 4px 4px;
  transition: ease 300ms;
`;

export const Ball = styled.span<{
  value: boolean;
}>`
  height: 18px;
  width: 18px;
  border-radius: 9px;
  background: ${({ value, theme }) => (value ? theme.palette.primary.text : theme.palette.primary.main)};
  margin-left: ${({ value }) => (value ? 24 : 0)}px;
  transition: ease 300ms;
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  margin-left: 12px;
  color: ${({ theme }) => theme.palette.primary.main};
`;
