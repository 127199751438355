import { ReactComponent as Bell } from 'assets/icons/bell.svg';
import { ReactComponent as BookSearch } from 'assets/icons/book-search.svg';
import { ReactComponent as Book } from 'assets/icons/book.svg';
import { ReactComponent as BookmarkFill } from 'assets/icons/bookmark-fill.svg';
import { ReactComponent as Bookmark } from 'assets/icons/bookmark.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Eye } from 'assets/icons/eye-slash.svg';
import { ReactComponent as EyeSlash } from 'assets/icons/eye.svg';
import { ReactComponent as Funnel } from 'assets/icons/funnel.svg';
import { ReactComponent as Gift } from 'assets/icons/gift.svg';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as InfoCircle } from 'assets/icons/info-circle.svg';
import { ReactComponent as List } from 'assets/icons/list.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as Padlock } from 'assets/icons/padlock.svg';
import { ReactComponent as PaperAirplane } from 'assets/icons/paper-airplane.svg';
import { ReactComponent as Profile } from 'assets/icons/profile.svg';
import { ReactComponent as Puzzle } from 'assets/icons/puzzle.svg';
import { ReactComponent as Pencil } from 'assets/icons/pencil.svg';
import { ReactComponent as PersonActive } from 'assets/icons/person-active.svg';
import { ReactComponent as PersonInactive } from 'assets/icons/person-inactive.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Rocket } from 'assets/icons/rocket.svg';
import { ReactComponent as Stopwatch } from 'assets/icons/stopwatch.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { ReactComponent as Trophy } from 'assets/icons/trophy.svg';

export default {
  bell: Bell,
  'book-search': BookSearch,
  book: Book,
  'bookmark-fill': BookmarkFill,
  bookmark: Bookmark,
  check: Check,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  close: Close,
  'eye-slash': EyeSlash,
  eye: Eye,
  funnel: Funnel,
  gift: Gift,
  home: Home,
  'info-circle': InfoCircle,
  list: List,
  logout: Logout,
  padlock: Padlock,
  'paper-airplane': PaperAirplane,
  profile: Profile,
  puzzle: Puzzle,
  pencil: Pencil,
  'person-active': PersonActive,
  'person-inactive': PersonInactive,
  plus: Plus,
  rocket: Rocket,
  stopwatch: Stopwatch,
  trash: Trash,
  trophy: Trophy,
};
