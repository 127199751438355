import { Route, Routes } from 'react-router-dom';
import List from './list';

const TemperatureUnit = (): JSX.Element => {
  return (
    <Routes>
      <Route index element={<List />} />
    </Routes>
  );
};
export default TemperatureUnit;
