import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from './components/Header';
import * as S from './styled';

const UserLayout: React.FC = () => {
  return (
    <S.Container>
      <Header />

      <S.Body>
        <S.BodyContent>
          <Outlet />
        </S.BodyContent>
      </S.Body>
    </S.Container>
  );
};

export default UserLayout;
