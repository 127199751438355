import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Body, Tabs } from 'components';
import Checklist from './checklist';
import Maintenance from './maintenance';

const Aviary: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ tab?: string }>();

  const tabsConfig = [
    {
      route: 'checklist',
      label: 'Checklist',
      Component: Checklist,
    },
    {
      route: 'maintenance',
      label: 'Manutenção',
      Component: Maintenance,
    },
  ];

  useEffect(() => {
    const tab = location.pathname.split('/')?.[3];

    if (!tab) {
      navigate(`${location.pathname}/${tabsConfig[0].route}`);
    }
  }, [params]);

  return (
    <>
      <Tabs config={tabsConfig} />

      <Body>
        <Routes>
          {tabsConfig.map(({ Component, route }) => (
            <Route key={route} path={`${route}/*`} element={<Component />} />
          ))}
        </Routes>
      </Body>
    </>
  );
};

export default Aviary;
