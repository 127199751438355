import { TextField } from 'components';
import validate from 'helpers/validate';

const LOCALES = [
  { schema: 'pt-br', label: 'Português' },
  { schema: 'en-us', label: 'Inglês' },
];

export const getLocaleValidations = () => {
  return LOCALES.reduce(
    (acc, item) => ({
      ...acc,
      [item.schema]: [validate.isEmpty()],
    }),
    {}
  );
};

export const getLocaleFields = (form, onChange) => {
  return LOCALES.map((item) => ({
    schema: item.schema,
    size: { md: 12 },
    type: TextField,
    props: (schema) => ({
      label: item.label,
      value: form.getValue(schema),
      error: form.getError(schema),
      onChange: onChange(schema),
    }),
  }));
};

export const getLocaleDataForm = (form) => {
  return LOCALES.reduce(
    (acc, item) => ({
      ...acc,
      [item.schema]: form.getValue(item.schema),
    }),
    {}
  );
};
