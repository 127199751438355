import { Route, Routes } from 'react-router-dom';
import List from './list';

const Language = (): JSX.Element => {
  return (
    <Routes>
      <Route index element={<List />} />
    </Routes>
  );
};
export default Language;
