import React from 'react';
import { Route, Routes } from 'react-router-dom';
import List from './list';
import Form from './form';

const Checklist = (): JSX.Element => {
  return (
    <Routes>
      <Route index element={<List />} />
      <Route path="create" element={<Form />} />
      <Route path="edit/:checklist" element={<Form />} />
    </Routes>
  );
};
export default Checklist;
