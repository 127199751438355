import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Footer, FormGrid, FormConfig, Header, Button, TextField, SelectField } from 'components';
import { useForm, useService } from 'hooks';
import validate from 'helpers/validate';
import { getLocaleValidations, getLocaleFields, getLocaleDataForm } from 'helpers/locale';
import { CoinUnitPosition } from 'shared/enums/generic';
import enumToLabelValue from 'utils/enumToLabelValue';

const validations = {
  name: [validate.isEmpty()],
  symbol: [validate.isEmpty()],
  symbomPosition: [validate.isEmptySelect()],
  precision: [validate.isEmpty()],
  decimalSeparator: [validate.isEmpty()],
  thousandsSeparator: [validate.isEmpty()],
  ...getLocaleValidations(),
};

const positionList = enumToLabelValue(CoinUnitPosition);

const Form: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ coinUnit?: string }>();
  const [form, onChange] = useForm({ validations });

  const LIST_CLASSES = `/params/general/coin-unit`;

  const createService = useService('post', `/coin-unit`, {}, false);
  const updateService = useService('patch', `/coin-unit/${params.coinUnit}`, {}, false);
  const coinUnitService = useService('get', `/coin-unit/${params.coinUnit}`, {}, !!params.coinUnit);

  const handleOnSave = async () => {
    const data = {
      name: form.getValue('name'),
      symbol: form.getValue('symbol'),
      symbomPosition: form.getValue('symbomPosition')?.value,
      precision: form.getValue('precision'),
      decimalSeparator: form.getValue('decimalSeparator'),
      thousandsSeparator: form.getValue('thousandsSeparator'),
      ...getLocaleDataForm(form),
    };

    const service = params.coinUnit ? updateService : createService;
    const response = await service.fetch(data);

    console.log(data);

    if (response?.data?.status === 'OK') {
      toast('Salvo com sucesso');
      return navigate(LIST_CLASSES);
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  const handleFillFields = useCallback(() => {
    const data = coinUnitService?.data?.data?.coinUnit;

    if (!data) return;

    form.setValues({
      ...data,
      symbomPosition: positionList?.find((fin) => fin?.value === data?.symbomPosition),
    });
  }, [coinUnitService?.data]);

  useEffect(() => {
    handleFillFields();
  }, [handleFillFields]);

  const formConfig: FormConfig = [
    [
      {
        schema: 'name',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
      {
        schema: 'symbol',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Símbolo',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
      {
        schema: 'symbomPosition',
        size: { md: 12 },
        type: SelectField,
        props: (schema) => ({
          label: 'Posição do símbolo',
          options: positionList,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
      {
        schema: 'precision',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Precisão',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
      {
        schema: 'decimalSeparator',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Separador Decimal',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
      {
        schema: 'thousandsSeparator',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Separador de milhar',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
    ],
    [...getLocaleFields(form, onChange)],
  ];

  return (
    <>
      <Header
        title={params.coinUnit ? 'Editar unidade de moeda' : 'Nova unidade de moeda'}
        left={
          <Button variant="outlined" to={LIST_CLASSES}>
            Voltar
          </Button>
        }
      />

      <FormGrid config={formConfig} />

      <Footer>
        <Button onClick={form.trySave(handleOnSave)} loading={createService.loading || updateService.loading}>
          Salvar
        </Button>
      </Footer>
    </>
  );
};

export default Form;
