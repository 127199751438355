import React from 'react';
import * as R from 'ramda';

import Loader from '../../components/Loader';
import Header from './components/Header';
import Item from './components/Item';
import Paginate from './components/Paginate';
import * as S from './styled';
import * as Types from './types';

interface TableListProps {
  config: Array<Types.ColumnProps>;
  data: Array<any>;
  dataPath?: string;
  loading?: boolean;
  paginate?: boolean;
  paginatePath?: string;
  onRowClick?: (row: any) => void;
  onChangePage?: (page: number) => void;
}

const List: React.FC<TableListProps> = ({
  config,
  data,
  dataPath,
  loading,
  paginate,
  paginatePath,
  onRowClick,
  onChangePage,
  ...props
}) => {
  const newData = React.useMemo(() => (dataPath ? R.pathOr([], dataPath.split('.'), data) : data), [data, dataPath]);
  const paginateData = React.useMemo(
    () => (paginatePath ? R.pathOr([], paginatePath.split('.'), data) : {}),
    [data, paginatePath]
  ) as Types.Paginate;

  return (
    <S.Container {...props}>
      <Loader show={loading}>
        <Header config={config} />

        {newData.map((item, key) => (
          <Item key={key} config={config} data={item} index={key} onRowClick={onRowClick} />
        ))}

        {newData.length ? null : <S.EmptyList>Nenhum registro encontrado</S.EmptyList>}

        {paginate ? <Paginate {...paginateData} onChange={onChangePage} /> : null}
      </Loader>
    </S.Container>
  );
};

export default List;
