import styled from 'styled-components';

import Grid from '../Grid';

export const Mask = styled(Grid)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.15);
  padding: 48px;
  margin: 0;
`;

export const Modal = styled(Grid)`
  position: relative;
  display: flex;
  background: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.15);
`;

export const Container = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  width: 100%;
  max-height: 80vh;
  padding: 12px;
`;

export const CloseButton = styled.span`
  position: absolute;
  display: flex;
  top: -44px;
  right: 0px;
  background: #fff;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: max-content;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 12px;
`;
