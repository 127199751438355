import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;

  & > a {
    display: flex;
    text-decoration: none;
  }
`;

export const Tab = styled.span<{ active?: boolean }>`
  padding: 12px 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  background: #fff;
  opacity: ${({ active }) => (active ? 1 : 0.7)};
  color: ${({ theme }) => theme.palette.primary.main};
  transition: 0.4s;

  &:hover {
    opacity: 1;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
