import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Body, Tabs } from 'components';
import WeightUnit from './weightUnit';
import TemperatureUnit from './temperatureUnit';
import TaskType from './taskType';
import Language from './language';
import Ingredient from './ingredient';
import CoinUnit from './coinUnit';
import IncubatorSpecieGroup from './incubatorSpecieGroup';

const General: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ tab?: string }>();

  const tabsConfig = [
    {
      route: 'weight-unit',
      label: 'Unidades de peso',
      Component: WeightUnit,
    },
    {
      route: 'temperature-unit',
      label: 'Unidades de temperatura',
      Component: TemperatureUnit,
    },
    {
      route: 'task-type',
      label: 'Tipo de tarefa',
      Component: TaskType,
    },
    {
      route: 'language',
      label: 'Linguagem',
      Component: Language,
    },
    {
      route: 'coin-unit',
      label: 'Unidade de moeda',
      Component: CoinUnit,
    },
    {
      route: 'incubator-specie-group',
      label: 'Pré-set incubadora',
      Component: IncubatorSpecieGroup,
    },
    {
      route: 'ingredient',
      label: 'Ingrediente',
      Component: Ingredient,
    },
  ];

  useEffect(() => {
    const tab = location.pathname.split('/')?.[3];

    if (!tab) {
      navigate(`${location.pathname}/${tabsConfig[0].route}`);
    }
  }, [params]);

  return (
    <>
      <Tabs config={tabsConfig} />

      <Body>
        <Routes>
          {tabsConfig.map(({ Component, route }) => (
            <Route key={route} path={`${route}/*`} element={<Component />} />
          ))}
        </Routes>
      </Body>
    </>
  );
};

export default General;
