import React from 'react';

import * as Types from './types';
import * as S from './styled';

import Row from './components/Row';

const FormGrid: React.FC<Types.FormGridProps> = ({ config, onSubmit, children, ...props }) => {
  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onSubmit?.();
  };
  return (
    <S.Container {...props} onSubmit={handleOnSubmit}>
      {config.map((row, key) => (
        <Row key={key} config={row} />
      ))}

      {children}
    </S.Container>
  );
};

export default FormGrid;

export type FormConfig = Array<Array<Types.FormGridColumnProps>>;
