import React from 'react';

const Birdie: React.FC = () => {
  return (
    <>
      Ciadouros
      <br />
    </>
  );
};

export default Birdie;
