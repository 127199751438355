import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  width: 300px;
  background: ${({ theme }) => theme.palette.primary.main};
  border-right: 4px solid ${({ theme }) => theme.palette.primary.main};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding: 20px;
`;

export const Logo = styled.img`
  height: 32px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 12px;
`;

export const UserName = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.primary.text};
`;

export const Navbar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.primary.text};
  padding-top: 4px;

  & > a {
    width: 100%;
  }
`;

export const NavbarItem = styled.div<{ active?: boolean }>`
  padding: 16px 16px;
  color: ${({ theme }) => theme.palette.primary.text};
  font-size: 14px;
  text-decoration: none;
  transition: 0.4s;
  width: 100%;

  :hover {
    background: ${({ theme }) => theme.palette.primary.text};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ active, theme }) =>
    !active
      ? ''
      : css`
          background: ${theme.palette.primary.text};
          color: ${theme.palette.primary.main};
        `};
`;

export const NavbarSubItemsContainer = styled.div`
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.palette.primary.text};
  transition: 0.4s ease;

  :hover,
  :has(.active) {
    & > .label {
      background: ${({ theme }) => theme.palette.primary.text};
      color: ${({ theme }) => theme.palette.primary.main};
    }

    & > .menu {
      padding: 8px 16px;
      height: auto;
    }
  }

  & > .label {
    padding: 16px 16px;
    font-size: 14px;
  }
`;

export const NavbarSubItems = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0;
  padding: 0;
`;

export const NavbarSubItem = styled.div<{ active?: boolean }>`
  padding: 16px 16px;
  color: ${({ theme }) => theme.palette.primary.text};
  font-size: 14px;
  text-decoration: none;
  width: 100%;
  border-bottom: 2px solid transparent;
  transition: 0.4s ease;

  :hover {
    border-color: ${({ theme }) => theme.palette.primary.text};
  }

  ${({ active, theme }) =>
    !active
      ? ''
      : css`
          border-color: ${({ theme }) => theme.palette.primary.text};
        `};
`;
