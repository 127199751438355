import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Form from './form';
import List from './list';

const Specie: React.FC = () => {
  return (
    <Routes>
      <Route index element={<List />} />
      <Route path="create" element={<Form />} />
      <Route path="edit/:specie" element={<Form />} />
    </Routes>
  );
};

export default Specie;
