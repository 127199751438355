import styled, { css } from 'styled-components';

import * as Types from './types';

export const Container = styled.div<{ width?: number | string }>`
  ${({ width }) => (width ? `width: ${typeof width === 'number' ? `${width}px` : width}` : 'unset')};
`;

interface ContainerProps {
  color: Types.Color;
  variant: Types.Variant;
  disabled: boolean;
  width?: number | string;
}

const VARIANTS: {
  [key: string]: {
    [type: string]: 'text' | 'main' | null;
  };
} = {
  default: {
    border: 'main',
    background: 'main',
    text: 'text',
  },
  outlined: {
    border: 'main',
    background: null,
    text: 'main',
  },
  transparent: {
    border: null,
    background: null,
    text: 'main',
  },
  gradient: {
    border: null,
    background: 'main',
    text: 'text',
  },
};

export const Content = styled.button<ContainerProps>`
  height: 44px;
  border-radius: 10px;
  font-size: 18px;
  width: max-content;
  padding: 0px 20px;

  ${({ color, variant, disabled, width, theme }) => {
    const borderType = VARIANTS[variant].border;
    const backgroundType = VARIANTS[variant].background;
    const colorType = VARIANTS[variant].text;

    let borderColor = borderType ? theme.helpers.getColor(color, borderType) : 'transparent';
    borderColor = `2px solid ${borderColor}`;
    let backgroundColor = backgroundType ? theme.helpers.getColor(color, backgroundType) : 'transparent';
    let textColor = colorType ? theme.helpers.getColor(color, colorType) : 'transparent';

    if (variant === 'gradient') {
      borderColor = 'none';
      backgroundColor = `linear-gradient(90deg, ${backgroundColor} 0%,  ${backgroundColor} 70%, transparent 100%)`;
    }

    return css`
      min-width: ${typeof width === 'number' ? `${width}px` : width};
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      border: ${borderColor};
      background: ${backgroundColor};
      color: ${textColor};
    `;
  }}
`;

interface LoaderProps {
  colorProp: Types.Color;
  variant: Types.Variant;
}

export const Loader = styled.span<LoaderProps>`
  span {
    ${({ variant, colorProp, theme }) => {
      const colorType = VARIANTS[variant].text;

      return css`
        background-color: ${colorType ? theme.helpers.getColor(colorProp, colorType) : 'transparent'} !important;
      `;
    }}
  }
`;
