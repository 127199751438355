import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.palette.gradient.main};
`;

export const Banners = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
`;

export const Banner = styled.div`
  display: flex;
  flex: 1;
  background: #fff;
  border-radius: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  overflow: auto;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 90%;
  background: #fff;
  padding: 12px;
  border-radius: 10px;
  align-self: center;
`;
