import React from 'react';
import { Route, Routes } from 'react-router-dom';
import List from './list';
import Form from './form';
import Question from './question';

const Health = (): JSX.Element => {
  return (
    <Routes>
      <Route index element={<List />} />
      <Route path="create" element={<Form />} />
      <Route path="edit/:health" element={<Form />} />
      <Route path=":health/question/*" element={<Question />} />
    </Routes>
  );
};
export default Health;
