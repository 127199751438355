export const getItem = (list, item) => {
  return list?.find((x) => x.value === item);
};

export const getLabel = (list, item) => {
  return getItem(list, item)?.label;
};

export const BIRDIE_USER_TYPES = [
  {
    value: 'BIRDIE',
    label: 'Administrador',
  },
  {
    value: 'COMMON',
    label: 'Comum',
  },
];

export const BIRDIE_USER_STATUS = [
  {
    value: 'ACTIVE',
    label: 'Ativo',
  },
  {
    value: 'INACTIVE',
    label: 'Inativo',
  },
];

export const BIRD_HEALTH_TYPES = [
  {
    value: 'PHYSICAL',
    label: 'Físico',
  },
  {
    value: 'VISUAL',
    label: 'Visual',
  },
];

export const AVIARY_CHECKLIST_TYPES = [
  {
    value: 'BIRD',
    label: 'Ave',
  },
  {
    value: 'AVIARY',
    label: 'Recinto',
  },
  {
    value: 'REPRODUCTION',
    label: 'Reprodução',
  },
];

export const BIRDIE_PARAM_COIN = [
  {
    value: 'REAL',
    'pt-br': 'Real',
    label: 'Real',
  },
  {
    value: 'AMERICAN_DOLLAR',
    'pt-br': 'Dollar Americano',
    label: 'Dollar Americano',
  },
  {
    value: 'CANADIAN_DOLLAR',
    'pt-br': 'Dollar Canadense',
    label: 'Dollar Canadense',
  },
  {
    value: 'EUROS',
    'pt-br': 'Euro',
    label: 'Euro',
  },
];

export const BIRDIE_PARAM_TEMPERATURE = [
  {
    value: 'CELSIUS',
    unit: '°C',
    'pt-br': 'Celsius',
    label: 'Celsius',
  },
  {
    value: 'FAHRENHEIT',
    unit: '°F',
    'pt-br': 'Fahrenheit',
    label: 'Fahrenheit',
  },
];

export const BIRDIE_PARAM_WEIGHT = [
  {
    value: 'METRIC',
    smallerUnit: 'g',
    biggerUnit: 'Kg',
    'pt-br': 'Métrico',
    label: 'Métrico',
  },
  {
    value: 'IMPERIAL',
    smallerUnit: 'oz',
    biggerUnit: 'lbs',
    'pt-br': 'Imperial',
    label: 'Imperial',
  },
];
