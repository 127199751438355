import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Body, Tabs } from 'components';
import EggBirthLocation from './eggBirthLocation';
import EggDeathReason from './eggDeathReason';
import EggLocation from './eggLocation';
import EggStatus from './eggStatus';
import PupStatus from './pupStatus';
import Status from './status';

const Reproduction: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ tab?: string }>();

  const tabsConfig = [
    {
      route: 'egg-birth-location',
      label: 'Local de Nascimento do ovo',
      Component: EggBirthLocation,
    },
    {
      route: 'egg-death-reason',
      label: 'Motivo da morte do ovo',
      Component: EggDeathReason,
    },
    {
      route: 'egg-location',
      label: 'Local de reprodução do ovo',
      Component: EggLocation,
    },
    {
      route: 'egg-status',
      label: 'Status de reprodução do ovo',
      Component: EggStatus,
    },
    {
      route: 'pup-status',
      label: 'Status do filhote',
      Component: PupStatus,
    },
    {
      route: 'status',
      label: 'Status',
      Component: Status,
    },
  ];

  useEffect(() => {
    const tab = location.pathname.split('/')?.[3];

    if (!tab) {
      navigate(`${location.pathname}/${tabsConfig[0].route}`);
    }
  }, [params]);

  return (
    <>
      <Tabs config={tabsConfig} />

      <Body>
        <Routes>
          {tabsConfig.map(({ Component, route }) => (
            <Route key={route} path={`${route}/*`} element={<Component />} />
          ))}
        </Routes>
      </Body>
    </>
  );
};

export default Reproduction;
