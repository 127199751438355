import { ThemeProvider, createGlobalStyle } from 'styled-components';

import theme from 'assets/theme';

interface ThemeProps {
  children: any;
}

const GlobalStyle = createGlobalStyle`
  body, html {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
    font-weight: normal;
    font-family: 'Montserrat', sans-serif;
  }
`;

const Theme: React.FC<ThemeProps> = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  );
};

export default Theme;
