import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Theme } from 'components';
import { AuthContext } from 'contexts';

import Routes from './Routes';

const App: React.FC = () => {
  return (
    <Theme>
      <AuthContext.Provider>
        <Routes />
      </AuthContext.Provider>

      <ToastContainer position="top-center" />
    </Theme>
  );
};

export default App;
