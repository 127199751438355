import React from 'react';
import { Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Body, Tabs } from 'components';

import Specie from './specie';
import ColorPattern from './colorPattern';
import Song from './song';
import Stats from './status';
import Gender from './gender';
import Health from './health';
import StatusColor from './statusColor';
import Mutation from './mutation';
import SpecieGroup from './specieGroup';

const Bird: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ tab?: string }>();

  const tabsConfig = [
    {
      route: 'specie',
      label: 'Espécies',
      Component: Specie,
    },
    {
      route: 'color-pattern',
      label: 'Padrão de cor',
      Component: ColorPattern,
    },
    {
      route: 'song-pattern',
      label: 'Padrão de canto',
      Component: Song,
    },
    {
      route: 'status',
      label: 'Status',
      Component: Stats,
    },
    {
      route: 'status-color',
      label: 'Cores de Status',
      Component: StatusColor,
    },
    {
      route: 'gender',
      label: 'Gênero',
      Component: Gender,
    },
    {
      route: 'health',
      label: 'Saúde',
      Component: Health,
    },
    {
      route: 'mutation',
      label: 'Mutações',
      Component: Mutation,
    },
    {
      route: 'specie-group',
      label: 'Grupo de espécies',
      Component: SpecieGroup,
    },
  ];

  React.useEffect(() => {
    const tab = location.pathname.split('/')?.[3];

    if (!tab) {
      navigate(`${location.pathname}/${tabsConfig[0].route}`);
    }
  }, [params]);

  return (
    <>
      <Tabs config={tabsConfig} />

      <Body>
        <Routes>
          {tabsConfig.map(({ Component, route }) => (
            <Route key={route} path={`${route}/*`} element={<Component />} />
          ))}
        </Routes>
      </Body>
    </>
  );
};

export default Bird;
