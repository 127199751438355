import React from 'react';
import { Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Body, Tabs } from 'components';
import DietComponentPrepare from './dietComponentPrepare';
import DietIngredientGroup from './dietIngredientGroup';

const Diet: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ tab?: string }>();

  const tabsConfig = [
    {
      route: 'diet-component-prepare',
      label: 'Preparação',
      Component: DietComponentPrepare,
    },
    {
      route: 'diet-ingredient-group',
      label: 'Grupo',
      Component: DietIngredientGroup,
    },
  ];

  React.useEffect(() => {
    const tab = location.pathname.split('/')?.[3];

    if (!tab) {
      navigate(`${location.pathname}/${tabsConfig[0].route}`);
    }
  }, [params]);

  return (
    <>
      <Tabs config={tabsConfig} />

      <Body>
        <Routes>
          {tabsConfig.map(({ Component, route }) => (
            <Route key={route} path={`${route}/*`} element={<Component />} />
          ))}
        </Routes>
      </Body>
    </>
  );
};

export default Diet;
