import React from 'react';
import { NavLink } from 'react-router-dom';

import LogoPNG from 'assets/images/birdie-white.png';
import { AuthContext } from 'contexts';

import * as S from './styled';

const UserLayout: React.FC = () => {
  const { state } = AuthContext.useAuth();

  const menuItems = [
    {
      name: 'Dashboard',
      link: '/dashboard',
    },
    {
      name: 'Usuários',
      link: '/users',
    },
    {
      name: 'Parâmetros',
      link: '/params',
    },
    {
      name: 'Financeiro',
      link: '/financial',
    },
  ];

  return (
    <S.Container>
      <S.Header>
        <S.Logo src={LogoPNG} />

        <S.UserInfo>
          <S.UserName>{state.user?.name}</S.UserName>

          <S.Logout to="/logout">Sair</S.Logout>
        </S.UserInfo>
      </S.Header>

      <S.Navbar>
        {menuItems.map((item) => (
          <NavLink key={item.link} to={item.link} style={{ textDecoration: 'none' }}>
            {({ isActive }) => <S.NavbarItem active={isActive}>{item.name}</S.NavbarItem>}
          </NavLink>
        ))}
      </S.Navbar>
    </S.Container>
  );
};

export default UserLayout;
