import React from 'react';
import { Outlet } from 'react-router-dom';

import LogoPNG from 'assets/images/birdie-blue.png';

import * as S from './styled';

const UserAuth: React.FC = () => {
  return (
    <S.Container>
      <S.Banners>
        <S.Banner />
      </S.Banners>

      <S.Content>
        <S.Logo src={LogoPNG} />

        <Outlet />
      </S.Content>
    </S.Container>
  );
};

export default UserAuth;
