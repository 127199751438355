import React from 'react';

import * as S from './styled';

interface BodyProps {
  children: any;
}

const Body: React.FC<BodyProps> = ({ children }) => {
  return <S.Container>{children}</S.Container>;
};

export default Body
