import { PulseLoader } from 'react-spinners';
import { Link, To } from 'react-router-dom';

import * as Types from './types';
import * as S from './styled';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children: any;
  color?: Types.Color;
  variant?: Types.Variant;
  disabled?: boolean;
  loading?: boolean;
  width?: number | string;
  to?: To;
  onClick?: () => void;
  type?: 'button' | 'submit';
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  color = 'primary',
  variant = 'default',
  disabled = false,
  loading = false,
  width = 'unset',
  type = 'button',
  children,
  to,
  ...props
}) => {
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (type === 'submit') return;
    if (to) return;

    e.preventDefault();
    e.stopPropagation();

    onClick?.();
  };

  const linkProps: { as: React.Component; to: To } | {} = to ? { as: Link, to } : {};

  return (
    <S.Container {...linkProps} width={width}>
      <S.Content
        onClick={handleOnClick}
        color={color}
        variant={variant}
        disabled={disabled || loading}
        width={width}
        type={type}
        {...props}>
        {loading ? (
          <S.Loader colorProp={color} variant={variant} as={PulseLoader} size={12} speedMultiplier={0.75} />
        ) : (
          children
        )}
      </S.Content>
    </S.Container>
  );
};

export default Button;
