import React from 'react';
import { NavLink } from 'react-router-dom';

import LogoPNG from 'assets/images/birdie-white.png';
import { AuthContext } from 'contexts';

import * as S from './styled';

const UserLayout: React.FC = () => {
  const { state } = AuthContext.useAuth();

  const menuItems = [
    {
      name: 'Dashboard',
      link: '/dashboard',
    },
    {
      name: 'Criadouros',
      link: '/birdies',
    },
    {
      name: 'Usuários',
      link: '/users',
    },
    {
      name: 'Parâmetros',
      link: '/params',
      items: [
        {
          name: 'Ave',
          link: '/params/bird',
        },
        {
          name: 'Dieta',
          link: '/params/diet',
        },
        {
          name: 'Reprodução',
          link: '/params/reproduction',
        },
        {
          name: 'Recinto',
          link: '/params/aviary',
        },
        {
          name: 'Geral',
          link: '/params/general',
        },
      ],
    },
  ];

  return (
    <S.Container>
      <S.Header>
        <S.Logo src={LogoPNG} />

        <S.UserInfo>
          <S.UserName>{state.user?.name}</S.UserName>
        </S.UserInfo>
      </S.Header>

      <S.Navbar>
        {menuItems.map((item) =>
          item?.items ? (
            <S.NavbarSubItemsContainer key={item.link}>
              <div className="label">{item.name}</div>

              <S.NavbarSubItems className="menu">
                {item.items.map((subItem) => (
                  <NavLink key={subItem.link} to={subItem.link} style={{ textDecoration: 'none' }}>
                    {({ isActive }) => <S.NavbarSubItem active={isActive}>{subItem.name}</S.NavbarSubItem>}
                  </NavLink>
                ))}
              </S.NavbarSubItems>
            </S.NavbarSubItemsContainer>
          ) : (
            <NavLink key={item.link} to={item.link} style={{ textDecoration: 'none' }}>
              {({ isActive }) => <S.NavbarItem active={isActive}>{item.name}</S.NavbarItem>}
            </NavLink>
          )
        )}
      </S.Navbar>
    </S.Container>
  );
};

export default UserLayout;
