import { format, parse, getUnixTime, fromUnixTime, isValid } from 'date-fns';

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_TIME_FORMAT = 'HH:mm';

export const isValidDate = (date: Date | string, FORMAT = DEFAULT_DATE_FORMAT as string): boolean => {
  if (!date) return false;

  const newDate = date instanceof Date ? date : parse(date, FORMAT, new Date());

  return isValid(newDate);
};

export const stringToDate = (date: string, FORMAT = DEFAULT_DATE_FORMAT): number => {
  const newDate = parse(date, FORMAT, new Date());
  return getUnixTime(newDate);
};

export const dateToString = (date: number, FORMAT = DEFAULT_DATE_FORMAT): string => {
  if (!date) return '';

  const newDate = fromUnixTime(date);

  return format(newDate, FORMAT);
};

export const newDbDate = (): number => {
  return getUnixTime(new Date());
};

export const secondsToString = (date: number): string => {
  const hours = Math.trunc(date / 3600);
  const hoursSeconds = hours * 3600;
  const minutes = Math.trunc((date - hoursSeconds) / 60);
  const seconds = date - hoursSeconds - minutes * 60;

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

export const stringToSeconds = (date: string): number => {
  const [hours, minutes, seconds] = date.split(':');

  return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
};
