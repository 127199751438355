import React from 'react';

const Dashboard: React.FC = () => {
  return (
    <>
      dashboard admin
      <br />
    </>
  );
};

export default Dashboard;
