import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import * as Admin from 'pages/admin';
import * as Auth from 'pages/auth';
import * as User from 'pages/user';
import { AuthLayout, UserLayout, AdminLayout } from 'templates';
import { PrivateRoute } from 'components';
import { AuthContext } from 'contexts';

const RoutesComponent: React.FC = () => {
  const { state } = AuthContext.useAuth();

  const authRoutes = React.useMemo(() => {
    if (!state.isLogged) return null;

    if (state.user?.type === 'ADMIN') {
      return {
        Template: AdminLayout,
        routes: [
          <Route key="birdies/*" path="birdies/*" element={<Admin.Birdie />} />,
          <Route key="dashboard/*" path="dashboard/*" element={<Admin.Dashboard />} />,
          <Route key="params/*" path="params/*" element={<Admin.Param />} />,
          <Route key="users/*" path="users/*" element={<Admin.User />} />,
        ],
      };
    }

    if (state.user?.type === 'BIRDIE') {
      return {
        Template: UserLayout,
        routes: [
          <Route key="dashboard/*" path="dashboard/*" element={<User.Dashboard />} />,
          <Route key="financial/*" path="financial/*" element={<User.Financial />} />,
          <Route key="params/*" path="params/*" element={<User.Params />} />,
          <Route key="users/*" path="users/*" element={<User.Users />} />,
        ],
      };
    }

    return null;
  }, [state]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route index element={<Auth.Login type="BIRDIE" />} />
          <Route path="admin" element={<Auth.Login type="ADMIN" />} />
          <Route path="register" element={<Auth.Register />} />
          <Route path="logout" element={<Auth.Logout />} />
          <Route path="auth/user/invite/:invite" element={<Auth.Invite />} />
        </Route>

        {!authRoutes?.Template ? null : <Route element={<authRoutes.Template />}>{authRoutes.routes}</Route>}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
