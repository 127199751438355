import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Form from './form';
import List from './list';


const Song = (): JSX.Element => {
  return (
    <Routes>
      <Route index element={<List />} />
      <Route path="create" element={<Form />} />
      <Route path="edit/:song" element={<Form />} />
    </Routes>
  );
};
export default Song;
