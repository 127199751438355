import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Form from './form';

const Users: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Form />} />
    </Routes>
  );
};

export default Users;
