import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TableList, Header, Button, TableListButtons, TableListButtonsProps } from 'components';
import { useService } from 'hooks';
import { BIRDIE_USER_STATUS, BIRDIE_USER_TYPES, getLabel } from 'helpers/consts';

const PAGE_KEY = 'users-page';

const List: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get(PAGE_KEY) || 1;

  const listService = useService('get', '/birdie-user', { page: currentPage }, true, [currentPage]);
  const removeService = useService('delete', (data) => `/birdie-user/${data?.id}`, {}, false);

  const handleOnChangePage = (page) => {
    setSearchParams({ [PAGE_KEY]: page });
  };

  const handleOnEdit = (row: any) => {
    navigate(`${location.pathname}/edit/${row._id}`);
  };

  const handleOnRemove = async (row: any) => {
    const response = await removeService.fetch({}, { id: row._id });

    if (response?.data?.status === 'OK') {
      toast('Usuário removido com sucesso');
      return listService.fetch();
    }
    return toast('Houve um erro ao remover', { type: 'error' });
  };

  const tableListButtonsConfig: TableListButtonsProps['config'] = [
    {
      icon: 'pencil',
      label: 'Editar',
      onClick: handleOnEdit,
    },
    {
      icon: 'trash',
      label: 'Excluir',
      onClick: handleOnRemove,
    },
  ];

  const listConfig = [
    {
      size: { md: 6 },
      path: 'name',
      title: 'Nome',
    },
    {
      size: { md: 2 },
      path: (row: any) => getLabel(BIRDIE_USER_TYPES, row.type),
      title: 'Tipo',
    },
    {
      size: { md: 2 },
      path: (row: any) => getLabel(BIRDIE_USER_STATUS, row.status),
      title: 'Status',
    },
    {
      title: '',
      size: {
        md: 2,
      },
      path: (row: any) => <TableListButtons data={row} config={tableListButtonsConfig} />,
    },
  ];

  return (
    <>
      <Header
        title="Usuários"
        right={
          <Button to={`${location.pathname}/create`} variant="outlined">
            Adicionar
          </Button>
        }
      />

      <TableList
        loading={listService.loading}
        config={listConfig}
        data={listService?.data?.data}
        dataPath="users.items"
        paginate
        paginatePath="users"
        onChangePage={handleOnChangePage}
      />
    </>
  );
};

export default List;
