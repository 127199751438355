import styled from 'styled-components';

import Grid from '../../../Grid';

export const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  background: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.text};
  font-size: 14px;
  height: 40px;
  align-items: center;
`;

export const Column = styled(Grid)`
  padding: 0 8px;
  font-weight: bold;
`;
