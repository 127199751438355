import React from 'react';
import { NavLink } from 'react-router-dom';

import * as S from './styled';

interface TabsProps {
  config: Array<{
    route: string;
    label: string;
  }>;
}

const Tabs: React.FC<TabsProps> = ({ config }) => {
  return (
    <S.Container>
      {config.map((item) => (
        <NavLink key={item.route} to={item.route}>
          {({ isActive }) => <S.Tab active={isActive}>{item.label}</S.Tab>}
        </NavLink>
      ))}
    </S.Container>
  );
};

export default Tabs;
